import React, { useState } from 'react';

import { wrapper, form, title as titleClass, successOutput } from './user-newsletter.module.scss';
import { INavLink } from '../../models/nav-link.model';
import { ISection } from '../../models/section.model';
import { IUserData } from '../../models/user.model';
import { IProfile } from '../../models/profile.model';
import { newsletterConsentForm } from '../../formik/newsletter-consent.form';
import { useUser } from '../../hooks/use-user';
import { useProfile } from '../../hooks/use-profile';
import useRtkQueryFormError from '../../hooks/use-rtk-query-form-error';
import useTranslations from '../../hooks/use-translations';

import UserLayout from '../../layouts/user-layout';
import Title from '../atoms/title';
import FormGenerator from '../molecules/form-generator';

export interface IUserNewsletterSection extends ISection {
    items: {
        links: INavLink[];
        newsletter: {
            newsletterId: number;
        };
    };
}

export interface IUserNewsletterProps {
    className?: string;
    section: IUserNewsletterSection;
}

export default function UserNewsletter({ className = '', section }: IUserNewsletterProps) {
    const t = useTranslations('UserNewsletter');
    const { sectionId, css, style } = section;
    const [subtitle, consentContent] = section.content.texts;
    const { links, newsletter } = section.items;
    const profile = useProfile();
    const userState = useUser();
    const { formikRef, globalErrorMessage } = useRtkQueryFormError(
        userState.toggleNewsletter.errors
    );
    const initialValues = createInitialValues(
        newsletter.newsletterId,
        userState.data,
        profile.data
    );
    const [visibleSuccessOutput, setVisibleSuccessOutput] = useState(false);

    const handleSubmit = async ({ consent }: { consent: boolean }) => {
        if (!profile.data) return;

        const body = {
            newsletter: newsletter.newsletterId,
            email: profile.data.email,
        };

        try {
            await userState.toggleNewsletter.fetch({
                ...body,
                action: consent ? 'subscribe' : 'unsubscribe',
                approval1: consent,
            });
            setVisibleSuccessOutput(true);

            setTimeout(() => {
                setVisibleSuccessOutput(false);
            }, 3000);
        } catch {}
    };

    return (
        <UserLayout
            sectionId={sectionId}
            className={className}
            links={links}
            css={css}
            style={style}
            childrenWrapperClassName={wrapper}
        >
            <Title Tag="h3" className={titleClass}>
                {subtitle}
            </Title>
            <FormGenerator
                formRef={formikRef}
                formikProps={{
                    onSubmit: handleSubmit,
                    initialValues: initialValues,
                    enableReinitialize: true,
                }}
                name="NewsletterConsentForm"
                fields={newsletterConsentForm}
                extraFieldsProps={{ consentContent }}
                formClassName={form}
                errorMessage={globalErrorMessage}
                submitLoading={userState.toggleNewsletter.isLoading}
                submitOnlyOnChange={true}
            >
                {visibleSuccessOutput && <output className={successOutput}>{t.success}</output>}
            </FormGenerator>
        </UserLayout>
    );
}

function createInitialValues(
    newsletterId: number,
    user: IUserData | null,
    profile: IProfile | null
) {
    return {
        consent: user?.newsletters?.find((newsletter) => newsletter.newsletterId === newsletterId)
            ? true
            : false ?? '',
        email: profile?.email ?? '',
    };
}
