import SectionHeader from '../components/sections/header';
import SectionUserNewsletter from '../components/sections/user-newsletter';
import SectionFooter from '../components/sections/footer';

import React, { useEffect } from 'react';
import { graphql, navigate } from 'gatsby';

import { IPage } from '../models/page.model';
import { useUser } from '../hooks/use-user';
import getPageConfigFromSections from '../utils/get-page-config-from-sections';
import getSectionsWithRepeatIndex from '../utils/get-sections-with-repeat-index';
import { usePagePathname } from '../hooks/use-page-pathname';

import MainLayout from '../layouts/main-layout';
import SectionFactory from '../components/hoc/section-factory';
import UpwardsButton from '../components/molecules/upwards-button';
import UserLoading from '../components/sections/user-loading';

interface IIndexProps {
    readonly data: {
        page: IPage;
    };
}

const User: React.FC<IIndexProps> = ({ data }) => {
    const { page } = data;
    const { mainSectionIndex } = getPageConfigFromSections(page.sections);
    const sections = getSectionsWithRepeatIndex(page.sections);
    const user = useUser();
    const paths = usePagePathname();

    useEffect(() => {
        if (user.isLoggedIn) return;
        navigate(paths.login || '/', { replace: true });
    }, [paths.login, user.isLoggedIn]);

    if (!user.isLoggedIn || user.get.isLoading) {
        return <UserLoading />;
    }

    return (
        <MainLayout>
            {sections.map((section, index) => {
                return (
                    <SectionFactory
                        key={`section-${section.type}-${section.sectionId}`}
                        SectionComponent={sectionComponents[section.type]}
                        section={section}
                        TitleTag={mainSectionIndex === index ? 'h1' : 'h2'}
                    />
                );
            })}
            <UpwardsButton />
        </MainLayout>
    );
};

export const query = graphql`
    query ($pageId: Int!, $locale: String!) {
        page(pageId: { eq: $pageId }, locale: { eq: $locale }) {
            sections {
                ...sectionFields
            }
        }
    }
`;

export default User;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';



const sectionComponents: Record<string, any> = {
    'header': SectionHeader,
    'user-newsletter': SectionUserNewsletter,
    'footer': SectionFooter,
};