import React, { CSSProperties } from 'react';

import {
    grid,
    wrapper,
    titleWrapper,
    title as titleClass,
    desktopNavigation,
    mobileNavigation,
    childrenWrapper,
    loader,
} from './user-layout.module.scss';

import { INavLink } from '../models/nav-link.model';
import { useProfile } from '../hooks/use-profile';
import { useUser } from '../hooks/use-user';
import useTranslations from '../hooks/use-translations';

import Title from '../components/atoms/title';
import UserDesktopNavigation from '../components/molecules/user-desktop-navigation';
import UserMobileNavigation from '../components/molecules/user-mobile-navigation';
import Loader from '../components/atoms/loader';

export interface IUserLayoutProps {
    sectionId: number;
    className?: string;
    childrenWrapperClassName?: string;
    children?: React.ReactNode | React.ReactNode[];
    links: INavLink[];
    css: string;
    style?: CSSProperties;
    header?: string;
}

export default function UserLayout({
    sectionId,
    className = '',
    childrenWrapperClassName = '',
    children,
    links,
    css,
    style,
    header,
}: IUserLayoutProps) {
    const t = useTranslations('UserLayout');
    const user = useUser();
    const profile = useProfile();

    const handleLogout = () => user.logout.fetch();

    return (
        <div
            id={`section-${sectionId ? sectionId : 'u'}`}
            className={[grid, className].join(' ')}
            style={style}
        >
            {css && <style>{css}</style>}
            <div className={titleWrapper}>
                <Title className={titleClass}>
                    {header
                        ? header
                        : `${t.header}${
                              profile.data?.firstName ? `, ${profile.data?.firstName}` : ''
                          }!`}
                </Title>
            </div>
            <div className={wrapper}>
                <UserDesktopNavigation
                    className={desktopNavigation}
                    links={links}
                    onLogout={handleLogout}
                />
                <UserMobileNavigation
                    className={mobileNavigation}
                    links={links}
                    onLogout={handleLogout}
                />
                <div className={[childrenWrapper, childrenWrapperClassName].join(' ')}>
                    {user.logout.isLoading && <Loader width="full" className={loader} />}
                    {children}
                </div>
            </div>
        </div>
    );
}
