import { useMemo } from 'react';

import useTranslations from './use-translations';
import { getRtkQueryErrors } from '../utils/get-rtk-query-errors';
import { useUser } from './use-user';
import { useGetProfileQuery, useUpdateProfileMutation } from '../redux/api/profile';
import { useSelector } from '../redux/hooks';
import { selectProfileState } from '../redux/slices/profile.slice';

export const useProfile = () => {
    const t = useTranslations('RTKQueryApi');

    const user = useUser();
    const profileState = useSelector(selectProfileState);

    const getProfileQuery = useGetProfileQuery(undefined, { skip: !user.isLoggedIn });
    const [updateProfile, updateProfileMutation] = useUpdateProfileMutation();

    const getErrors = useMemo(() => {
        return getRtkQueryErrors(getProfileQuery.error, t.errors);
    }, [getProfileQuery.error, t.errors]);
    const updateErrors = useMemo(() => {
        return getRtkQueryErrors(updateProfileMutation.error, t.errors);
    }, [updateProfileMutation.error, t.errors]);

    return {
        ...profileState,
        get: {
            data: getProfileQuery.data,
            isFetching: getProfileQuery.isFetching,
            isLoading: getProfileQuery.isLoading,
            isSuccess: getProfileQuery.isSuccess,
            isError: getProfileQuery.isError,
            errors: getErrors,
        },
        update: {
            fetch: updateProfile,
            data: updateProfileMutation.data,
            isLoading: updateProfileMutation.isLoading,
            isSuccess: updateProfileMutation.isSuccess,
            isError: updateProfileMutation.isError,
            errors: updateErrors,
        },
    };
};
